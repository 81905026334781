










































































































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'decorate-button': () => import('@/modules/popo/components/decorate-button.vue'),
    'attach-nft-tab': () => import('@/modules/popo/components/attachs/attach-nft-tab.vue'),
    'attach-nft-card': () => import('@/modules/popo/components/attachs/attach-nft-card.vue'),
  },
})
export default class extends Vue {
  tab = null
  itemPerRow = 3
  popoStore = popoStore

  changeAttach(isChecked, nft) {
    this.popoStore.changeAttachNft(isChecked, { ...nft })
    if (!isChecked) {
      const el = this.$refs[`attach-nft-tab-${nft.type}`] as any
      if (!el?.length) return
      el[0]?.clearSelectedNft()
    }
  }
}
